<template>
    <app-page :title="pageTitle">
        <section class="flex-grow-1 align-items-stretch">
            <center-container class="d-flex flex-column"
                              row-class="h-100"
                              col-class="col-xs-no-gutter d-flex">
                <b-tabs content-class="bg-white" v-model="tabIndex">
                    <b-tab class="document-tab px-3" title="Factures">
                        <b-row v-if="invoices.length > 0">
                            <time-splitter class="w-100 pt-3 px-3"
                                           :list="invoices"
                                           prop="bill_date">
                                <template #default="splitterProps">
                                    <div v-for="(invoice, index) in splitterProps.split"
                                         class="d-flex align-items-center justify-content-between py-3 border-gray"
                                         :class="{'border-bottom': index < splitterProps.split.length - 1}"
                                         :key="`day-${index}`">
                                        <div class="pr-4">
                                            <p>Facture n°{{ invoice.document_number }} du
                                                {{ formatDate(invoice.bill_date, '_D_/_M_/_Y_') }}</p>
                                            <div class="d-flex align-items-center">
                                                <p class="font-weight-bold family-bold pr-3">{{
                                                        String(Number.parseFloat(invoice.amount_ttc).toFixed(2)).replace('.', ',')
                                                    }}€</p>
                                                <b-badge class="rounded-pill"
                                                         :variant="paymentStatuses[invoice.payment_status].class">
                                                    {{ paymentStatuses[invoice.payment_status].text }}
                                                </b-badge>
                                            </div>
                                        </div>
                                        <b-link @click="getInvoice(invoice.idinvoice)" class="text-nowrap">Voir
                                            <b-icon-eye class="h4"/>
                                        </b-link>
                                    </div>
                                </template>
                            </time-splitter>
                        </b-row>
                        <p v-else class="w-100 py-3 px-3 text-center">Aucune facture disponible</p>
                    </b-tab>
                    <b-tab class="document-tab px-3" title="Attestations">
                        <b-row v-if="attestations.length > 0">
                            <time-splitter class="w-100 pt-3 px-3 bg-white"
                                           :list="attestations"
                                           prop="computed_date">
                                <template #default="splitterProps">
                                    <div v-for="(certificate, index) in splitterProps.split"
                                         class="d-flex align-items-center justify-content-between py-3 border-gray"
                                         :class="{'border-bottom': index < splitterProps.split.length - 1}"
                                         :key="`day-${index}`">
                                        <p class="pr-3">Attestation fiscale {{ certificate.year_validity }}</p>
                                        <b-link class="text-nowrap"
                                                @click="getAttestation(certificate.idtax_certificate)">Voir
                                            <b-icon-eye class="h4"/>
                                        </b-link>
                                    </div>
                                </template>
                            </time-splitter>
                        </b-row>
                        <p v-else class="w-100 py-3 px-3 bg-white text-center">Aucune attestation disponible</p>
                    </b-tab>
                    <b-tab class="document-tab" title="Contrats">
                        <Contrats></Contrats>
                    </b-tab>
                </b-tabs>
            </center-container>
        </section>
        <PdfViewer
            v-if="promise"
            :promise="promise"
            @exit-pdf="promise = null"
        ></PdfViewer>
    </app-page>
</template>

<script>
import AppPage from '@/components/layout/AppPage'
import PdfViewer from "@/components/layout/elements/PdfViewer";
import CenterContainer from '@/components/layout/CenterContainer'
import TimeSplitter from '@/components/layout/elements/TimeSplitter'
import formatDate from '@/mixins/format-date'
import downloadFile from '@/mixins/download-file'
import Contrats from './Contrats'
import {BIconEye} from 'bootstrap-vue'

import contratsService from '../../../services/contratsService'

export default {
    components: {TimeSplitter, CenterContainer, AppPage, BIconEye, PdfViewer, Contrats},
    mixins: [formatDate, downloadFile],
    data() {
        return {
            paymentStatuses: {
                paid: {
                    class: 'success',
                    text: 'Payée'
                },
                partially_paid: {
                    // class: 'warning',
                    class :'primary',
                    text: 'Partiellement payée'
                },
                unpaid: {
                    class: 'danger',
                    text: 'Non payée'
                },
            },
            promise: null,
            invoices: [],
            attestations: [],
            tabIndex: (localStorage?.getItem('tabIndex') ?? 0) * 1
        }
    },
    computed: {
        pageTitle() {
            return 'Vos documents'
        }
    },
    watch: {
        tabIndex(val) {
            localStorage.setItem('tabIndex', val);
        }
    },
    created() {
        this.getAttestations();
        this.getInvoices();
    },
    mounted() {

    },
    methods: {
        getInvoice(id) {
            this.promise = contratsService.getFacture(id)
        },
        getAttestation(id) {
            this.promise = contratsService.getAttestation(id)
        },
        getInvoices() {
            contratsService.getFactures().then((response) => {
                if (response.data.etat === 'OK') {
                    this.invoices = response.data.data.reduce((acc, curr) => {
                        return [...acc, ...curr.invoices];
                    }, []);

                    this.invoices?.forEach(item => {
                        if (item.ledger_funds_entry && item.ledger_funds_entry.amount_rest == 0)
                            item.payment_status = 'paid'
                        else if (item.ledger_funds_entry && item.ledger_funds_entry.amount_rest != 0 && item.ledger_funds_entry.amount_rest == item.amount_ttc)
                            item.payment_status = 'unpaid'
                        else if (item.ledger_funds_entry && item.ledger_funds_entry.amount_rest != 0 && item.ledger_funds_entry.amount_rest != item.amount_ttc)
                            item.payment_status = 'partially_paid'
                        else
                            item.payment_status = null
                    })
                    this.invoices?.sort((a, b) => {
                        if (this.$moment(a.created_at) > this.$moment(b.created_at)) return -1;
                        return 1;
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getAttestations() {
            contratsService.getAttestations().then((response) => {
                if (response.data.etat === 'OK') {
                    this.attestations = response.data.data.reduce((acc, curr) => {
                        return [...acc, ...curr.attestations];
                    }, []);
                    this.attestations?.forEach(item => {
                        item.computed_date = this.$moment().year(item.year_validity).format('YYYY-MM-DD')
                    })
                    this.attestations?.sort((a, b) => {
                        if (this.$moment(a.created_at) > this.$moment(b.created_at)) return -1;
                        return;
                    })
                }
            }).catch((error) => {
                console.log(error)
            })
        },
    }
}
</script>

<style scoped>
>>> .nav-item {
    min-width: 20px !important;
}

>>> .nav .nav-tabs {
    justify-content: space-around;
}

>>> .nav-link {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    color: white;
}

>>> .tab-content {
    padding: 0;
}
</style>