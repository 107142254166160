<template>
    <b-container>
        <div class="w-100"
             v-for="(t, index) in formattedContracts" :key="index">
            <b-row class="w-100 bg-primary">
                <b-col cols="12">
                    <h2 class="p-1 text-white">{{ t.title }}</h2>
                </b-col>
            </b-row>
            <b-row v-if="t.data.length">
                <b-col v-for="contract in t.data"
                       class="d-flex col-xs-no-gutter pb-0 pb-sm-5"
                       md="6" xl="4"
                       :key="`contract-${contract.idcontract}`">
                    <b-card class="w-100 pb-2 pb-sm-0">
                        <template #header>
                            <p class="h4 text-center">Votre contrat n°{{ contract.num_contract }}</p>
                        </template>
                        <div class="h-100 d-flex flex-column">
                            <div class="flex-grow-1 pb-2 d-flex align-items-center">
                                <div class="flex-grow-1 align-self-start pr-4">
                                    <p>{{ contract.activity_name }}</p>
                                    <!--TODO-->
                                    <p>{{ contract.hours_each_intervention }} {{ contract.frequence }}</p>
                                    <p>{{ contract.state_name }}</p>
                                </div>
                                <b-link class="text-nowrap d-flex align-items-center mr-3"
                                        v-if="contract.profiles && contract.profiles.length"
                                        @click="() => { consignesAction(contract) }">
                                    Consignes
                                    <component :is="$appVersion('contractDetailIcon')"
                                               class="h4 ml-1"/>
                                </b-link>

                                <b-link class="text-nowrap d-flex align-items-center"
                                        @click="() => { detailAction(contract) }">
                                    {{ $appVersion('contractDetail') }}
                                    <component :is="$appVersion('contractDetailIcon')"
                                               class="h4 ml-1"/>
                                </b-link>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <p v-else class="text-black-50 text-center mt-2">Aucun contrat à afficher</p>
        </div>
    </b-container>
</template>

<script>
import {BIconEye, BIconChevronRight} from 'bootstrap-vue'
import AppPage from '@/components/layout/AppPage'
import CenterContainer from '@/components/layout/CenterContainer'
import TimeSplitter from '@/components/layout/elements/TimeSplitter'
import formatDate from '@/mixins/format-date'
import contratsService from '../../../services/contratsService'

export default {
    components: {TimeSplitter, CenterContainer, AppPage, BIconEye, BIconChevronRight},
    mixins: [formatDate],
    computed: {
        pageTitle() {
            return this.archive ? 'Vos anciens contrats' : 'Vos contrats'
        },
        archiveBtnText() {
            return this.archive ? 'contrats en cours' : 'anciens contrats'
        },
        archivedContracts() {
            return this.contracts.filter(contract => contract.state_id == 7)
        },
        currentContracts() {
            return this.contracts.filter(contract => contract.state_id != 7)
        },
        formattedContracts() {
            const a = [];
            if (this.currentContracts.length || this.archivedContracts.length == 0) a.push({
                title: 'Contrats actifs',
                data: this.currentContracts
            });
            if (this.archivedContracts.length) a.push({title: 'Contrats archivés', data: this.archivedContracts});
            return a;
        }
    },
    mounted() {
        this.getContrats()
    },
    data() {
        return {
            contracts: [],
            list: [],
            invoices: [],
            attestations: []
        }
    },
    watch: {
        archive(newVal) {
            this.filterArchive()
        }
    },
    methods: {
        getContrats() {
            contratsService.getContrats().then((response) => {
                if (response.data.etat === 'OK') {
                    this.contracts = response.data.data
                    this.filterArchive()
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        filterArchive() {
            this.list = this.archive ? this.contracts.filter(contract => contract.state_id == 7) : this.contracts.filter(contract => contract.state_id != 7)
        },

        detailAction(contract) {
            this.$nextTick(() => {
                this.$router.push({name: 'detail-contrat', params: {currentContract: contract}})
            })
        },
        
        consignesAction(contract) {
            this.$nextTick(() => {
                this.$router.push({name: 'consignes-contrat', params: {currentContract: contract}})
            })
        }
    }
}
</script>